<template>
 <div v-if="true">
   <div style="padding: 10px" class="SectionAdap">
     <div class="MyLimitBorder">
       <div class="half">
         {{ $t("actions.cardinfo.tickets") }}
         <div style="width: 100%">
           <strong>TOTAL ${{ $root.formatPrice(getTotalRegular()) }} MX</strong>
         </div>
       </div>
       <div v-if="this.$store.state.sites.statistics === 0" class="half Mycolor" align="right">
         <v-icon style="color: var(--color-primary); transform: rotate(135deg)">mdi-ticket-outline</v-icon>
         X
         <span class="font-weight-bold" :class="[{'red--text' : getNumberOfTickets() > 20}]">
          {{ getNumberOfTickets() }}
        </span> / 20
       </div>
       <div v-if="this.$store.state.sites.statistics !== 0" class="half Mycolor" align="right">
         <v-icon style="color: var(--color-primary); transform: rotate(135deg)">mdi-ticket-outline</v-icon>
         X
         <span class="font-weight-bold" :class="[{'red--text' : getNumberOfTickets() > getTotalAvailableTickets()}]">
          {{ getNumberOfTickets() }}
        </span> / {{ getTotalAvailableTickets() }}
       </div>
     </div>
     <div
       style="padding: 10px 10px 0px 10px"
       v-if="$store.state.sites.indexEveSelSection != null"
     >
       <h5 v-if="$store.state.sites.indexEveSelSection == null">
         <strong>Seleccione una sección</strong>
       </h5>
       <div v-if="$store.state.sites.indexEveSelSection != null">
         <div>
           <table>
             <tr>
               <td align="left" class="tablepaddingH">
                 <strong>AREA:</strong>
               </td>
               <td align="left" class="tablepadding">
                 {{ $store.state.sites.temp_eventSelect.area }}
               </td>
             </tr>
             <tr>
               <td align="left" class="tablepaddingH">
                 <strong>SECTION:</strong>
               </td>
               <td align="left" class="tablepadding" style="font-size: 18px">
                 {{ $store.state.sites.temp_eventSelect.section }}
               </td>
             </tr>
           </table>
         </div>
         <div align="center">
           <InputNumericVue
             ref="InputNumericVue"
             :max="$store.state.sites.temp_eventSelect.quedan"
             style="max-width: 300px"
           />
         </div>
         <div align="center">
           <div style="padding-top: 20px; max-width: 450px">
             <div align="left">
               <strong>Costo Unitario ${{ Inputnumeric.precio }}</strong>
             </div>
             <div align="right">
               <h4>Costo Total ${{ $root.formatPrice(Inputnumeric.suma) }}</h4>
             </div>
             <div style="color: red; padding-bottom: 20px">
               *Maximo de 100 boletos por transaccion
               <!-- {{ vEntorno.temp_eventSelect.max_seat }} -->
             </div>
             <div>
               <v-row>
                 <v-col cols="6">
                   <v-btn
                     color="red"
                     dark
                     @click="CancelarSection()"
                     style="min-width: 148px"
                     >{{ $t('actions.cancel') }}
                   </v-btn>
                 </v-col>
                 <v-col cols="6">
                   <v-btn
                     color="green"
                     dark
                     @click="SelecSection()"
                     style="min-width: 148px"
                     >{{ $t('actions.step.confirma1') }}
                   </v-btn>
                 </v-col>
               </v-row>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>

   <div
     class="SectionAdap"
     :class="{'DivTicket': true, 
     'hideDivTicket': $store.state.sites.indexEveSelSection != null}"
   >
     <div
       v-for="(item, index) in $store.state.shopping.regularSellTickets"
       :key="'tickets-' + index"
       @mouseenter="HoverIn(item)"
       @mouseleave="Hoverout(item)"
     >
       <Boleto
         :item="item"
         v-if="item.showseat === 1"
       />
       <BoletosSec
          :item="item"
          @click.native="SelecSection(item)"
          v-if="item.showseat === 0"
          @selectSection="SelecSection"
          @removeSelection="(val) => {
            removeSelectFlag = val
          }"
       />
     </div>
   </div>
   <div class="d-flex flex-row container-button-buy"
     v-if="$store.state.sites.indexEveSelSection == null">
     <v-btn
        v-if="this.$store.state.sites.statistics !== 0" 
        class="my-3 d-block mx-auto"
        color="black"
        dark
        @click="$emit('openDialogTicketSel')"
      >
        {{ $t('sales.newSelec') }}&nbsp;
        <v-icon size="18" dark style="transform: rotate(135deg)">mdi-ticket-outline</v-icon>
      </v-btn>
      <v-btn
        :disabled="getNumberOfTickets() === 0" 
        class="my-3 d-block mx-auto"
        color="var(--color-primary)"
        dark
        @click="proceedToBuy()"
      >
        {{ $t('actions.shop') }}
      </v-btn>
   </div>
 </div>
</template>

<script>
import Boleto from "../Tools/Boletos.vue";
import BoletosSec from "../Tools/BoletosSec.vue";
import InputNumericVue from "../Tools/InputNumeric2.vue";

import $ from "jquery";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MapSelectedTickets",
  components: {
    Boleto,
    BoletosSec,
    InputNumericVue,
  },
  data() {
    return {
      vEntorno: null,
      Inputnumeric: null,
      total: 0,
      max: 0,
      tickets: [],
      removeSelectFlag: false
    };
  },
  created() {
    this.vEntorno = this.$store.state.sites;
    this.vEntorno.tickets = this.$store.state.shopping.regularSellTickets;
    this.Inputnumeric = this.$Inputnumeric;
  },
  mounted() {
    this.vEntorno = this.$store.state.sites;
    this.vEntorno.tickets = this.$store.state.shopping.regularSellTickets;
    this.Inputnumeric = this.$Inputnumeric;
  },
  methods: {
    ...mapGetters({
      getNumberOfTickets: 'shopping/getNumberRegular',
      getTotalRegular: 'shopping/getRegularTotal',
      getTotalAvailableTickets: 'shopping/getTotalAvailableTickets'
    }),
    ...mapActions({
      changeSectionSelec: 'sites/changeSelectedSection',
      upgradeTicketSelection: 'shopping/updateRegularPurchase',
      setContinueRegShopFlag: 'shopping/setFlagBackRegularBuy'
    }),
    async proceedToBuy() { 
      return this.$root.swalAlert('warning', this.$t("sales.serviceUnavailableMessage"));
      /*if (!this.$root.token) {
        this.$root.showlogin = true;
        this.$root.loading = false;
        return false;
      }
      if(this.getNumberOfTickets() > 20) {
        this.$root.swalAlert("warning", this.$t('messages.ticketsNumberMustLessHundred'), null);
        return
      }
      if(this.$store.state.sites.statistics !== 0 && this.getTotalAvailableTickets() !== this.getNumberOfTickets()) {
        this.$root.swalAlert("warning", this.$t('messages.ticketsMustMatch'), null);
        return
      }
      this.$emit("startReserve", this.vEntorno.events[this.vEntorno.indexEveSel].event_config, this.vEntorno.tickets)*/
    },
    HoverIn(item) {
      $("#Gp_" + item.shape + "_" + item.row + "_" + item.column + "").css({
        stroke: "red",
        "stroke-width": "2",
      });
    },
    Hoverout(item) {
      $("#Gp_" + item.shape + "_" + item.row + "_" + item.column + "").css({
        stroke: "transparent",
      });
    },
    CancelarSection() {
      this.changeSectionSelec({ index: null, section: null })
    },
    SelecSection(item) {
      if(this.removeSelectFlag) return false;

      if (!this.Inputnumeric.cantidad) {
        if (!item) {
          this.$root.swalAlert(
            "warning",
            "",
            "La cantidad de boletos debe ser mayor a " + this.Inputnumeric.cantidad
          );
          return false;
        }
      }
      if (item) {
        this.changeSectionSelec({ index: item.id_section, section: item })

        setTimeout(() => {
          this.processSection(item)
        }, 50);
      } else {
        this.processSection()
      }
    },
    processSection(item) {
      let tickets;
      if (item) {
        this.$set(this.$Inputnumeric, "cantidad", item.cantidad)
        this.$set(this.$Inputnumeric, "precio", item.price)
        this.$set(this.$Inputnumeric, "suma", item.total)
        this.changeSectionSelec({ section: item, index: item.id_section })
      }

      let vx = this.vEntorno.tickets.findIndex((busca) => busca.id_section === this.vEntorno.temp_eventSelect.id_section);
      if (vx > -1) {
        let tickets = this.vEntorno.tickets[vx];
        tickets.cantidad = parseInt(this.Inputnumeric.cantidad);
        if (tickets.cantidad > this.vEntorno.temp_eventSelect.n_seating) {
          tickets.cantidad = this.vEntorno.temp_eventSelect.n_seating;
        }
        tickets.total = tickets.cantidad * tickets.price;
      } else {
        tickets = {
          max_seat: this.$store.state.sites.temp_eventSelect.n_seating,
          indexEveSelSection: this.$store.state.sites.indexEveSelSection,
          id_section: this.$store.state.sites.temp_eventSelect.id_section,
          area: this.$store.state.sites.temp_eventSelect.area,
          section: this.$store.state.sites.temp_eventSelect.section,
          id_boleto: this.$store.state.sites.temp_eventSelect.id_boleto,
          cantidad: this.Inputnumeric.cantidad,
          price: this.Inputnumeric.precio,
          total: this.Inputnumeric.suma,
          showseat: this.$store.state.sites.temp_eventSelect.showseat
        };
        this.vEntorno.tickets.unshift(tickets);
      }
      this.upgradeTicketSelection(this.vEntorno.tickets)

      if (!item) {
        this.changeSectionSelec({ index: null, section: null })
      }
    }
  },
};
</script>

<style scoped>
.container-button-buy {
 border-top: 1px solid #ccc;
 box-sizing: border-box;
}
.tablepaddingH {
 padding: 0px 0px 5px 0px;
}
.tablepadding {
 padding: 0px 0px 5px 20px;
}
.DivTicket {
 height: calc(100% - 151px) !important;
 overflow: auto;
}
.DivTicket2 {
 height: calc(100% - 182px) !important;
 overflow: auto;
}
.hideDivTicket {
  display: none;
}
.MyLimitBorder {
 border-bottom: 2px solid #dddcdc;
 padding: 0px 10px 10px 10px !important;
 text-align: left;
 display: flex;
 align-items: center;
}
.half {
 width: 50%;
}
.half div {
 font-size: 22px;
}
.Mycolor {
 color: black;
 font-size: 16px;
}

@media (max-width: 800px) {
 /* .SectionAdap{
   zoom: 80%;
 } */
 .DivTicket {
   height: auto !important;
   overflow: hidden;
 }
 .half div {
   font-size: 17px;
 }
 .DivTicket2 {
   height: auto !important;
   overflow: hidden;
 }
}
</style>