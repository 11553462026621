<template>
 <div
   style="padding: 5px 19px 30px; box-sizing: border-box"
   @click="ChangePrice(item)"
 >
   <v-card hover style="padding-top: 10px">
     <v-row class="MyPadding">
       <v-col cols="4" align="left">
         <div class="Pleft">
           <div class="Mycolor">{{ $t("actions.cardinfo.section") }}</div>
           <div class="MyFont1">{{ item.section }}</div>
         </div>
       </v-col>
       <v-col cols="4" align="left">
         <div class="Pleft">
           <div class="Mycolor">{{ $t("actions.cardinfo.price") }}</div>
           <div class="MyFont1">${{ $root.formatPrice(item.price) }}</div>
         </div>
       </v-col>
       <v-col cols="4" align="center">
         <div class="Pleft">
           <div class="Mycolor">{{ $t("actions.cardinfo.amount") }}</div>
           <div class="MyFont1">{{ item.cantidad }}</div>
         </div>
       </v-col>
       <v-col cols="12" style="padding-top: 0">
         <div class="MyPadding" align="left">
           <div class="MyLimitBorder"></div>
           <div style="display: flex; align-items: center">
             <div
               class="Mymoney"
               style="width: calc(100% - 50px)"
               align="right"
             >
               TOTAL: ${{ $root.formatPrice(item.total) }}
             </div>
             <div align="right" style="padding: 10px; width: 50px">
               <v-tooltip top>
                 <template v-slot:activator="{ on, attrs }">
                   <v-btn
                     dark
                     color="var(--color-primary)"
                     elevation="2"
                     x-small
                     fab
                     v-bind="attrs"
                     v-on="on"
                     @click="removeTicket(item)"
                   >
                     <v-icon>mdi-trash-can</v-icon>
                   </v-btn>
                 </template>
                 <span>Quitar de la lista</span>
               </v-tooltip>
             </div>
           </div>
         </div>
       </v-col>
     </v-row>
   </v-card>
 </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
 name: "BoletosSec",
 props: ["item"],
 data() {
   return {
     vEntorno: null,
     flag: false,
     Inputnumeric: null
   };
 },
 created() {
   this.vEntorno = this.$store.state.sites;
   this.Inputnumeric = this.$Inputnumeric;
 },
 mounted() {
   this.vEntorno = this.$store.state.sites;
   this.vEntorno.tickets = this.$store.state.shopping.regularSellTickets;
   this.Inputnumeric = this.$Inputnumeric;
 },
 methods: {
  ...mapActions({
    upgradeTicketSelection: 'shopping/updateRegularPurchase',
    changeSectionSelec: 'sites/changeSelectedSection'
  }),
   ChangePrice(item) {
    if (!this.flag) {
      this.$emit('removeSelection', false)
      this.$emit("selectSection", item)
    }
    this.flag = !this.flag;
   },
   removeTicket(item) {
     this.flag = true;
     this.$emit('removeSelection', true)
     this.$swal({
       title: "Deseas eliminar los boletos de esta seccion?",
       text: "",
       showDenyButton: true,
       showCancelButton: false,
       confirmButtonText: "Si",
     }).then((result) => {
       if (result.isConfirmed) {
         var vx = this.vEntorno.tickets.findIndex(
           (busca) => busca.id_section === item.id_section
         );
         this.vEntorno.tickets.splice(vx, 1);
         this.upgradeTicketSelection(this.vEntorno.tickets)
         this.changeSectionSelec({ index: null, section: null })
       }
       this.$emit('removeSelection', false)
     });
   },
 },
};
</script>

<style scoped>
.Mymoney {
 padding: 10px;
 font-weight: bold;
 font-size: 16px;
}
.Mycolor {
 color: var(--color-primary);
}
.MyFont1 {
 font-size: 18px;
}
.Pleft {
 padding-left: 10px;
}
.half {
 width: 50%;
}
.MyLimitBorder {
 border-bottom: 2px solid #dddcdc;
 padding: 0px 10px 10px 10px !important;
 text-align: left;
 display: flex;
}
.MyPadding {
 padding: 0px 10px 0px 10px !important;
}
.hr-vertical {
 border: none;
 border-left: 1px solid hsla(200, 10%, 50%, 100);
 height: 100px;
 width: 1px;
}
</style>