<template>
  <div style="position: relative;">
    <div class="MyToolTip"></div>
    <div style="position:absolute; z-index:10; top:10px; left:10px;">
      <v-btn color="var(--color-primary)" @click="goBackHome" dark style="padding: 22px 5px; min-width: 50px !important;">
          <v-icon>
            mdi-home
          </v-icon>
          <span>{{ $t("actions.goBack") }}</span>
        </v-btn>
    </div>
    <div style="position:absolute; z-index:10; top:10px; left:calc(100% - 165px); display: flex;">
      <v-btn color="var(--color-primary)" dark @click="begin(MapSvg)" style="padding: 22px 0px; min-width: 50px !important;" >
        <v-icon>
          mdi-image-filter-center-focus-weak
        </v-icon>
      </v-btn>
      <v-card class="pa-0 ml-4">
          <div class="d-inline-block" style="padding: 4px; border-right: 1px solid #ccc; border-radius: 0px">
            <v-btn color="var(--color-primary)" dark icon @click="ZoomWithButtom(0.15)">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
          <div class="d-inline-block" style="padding: 4px;">
            <v-btn color="var(--color-primary)" dark icon @click="ZoomWithButtom(-0.15)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </v-card>
    </div>

    <div v-show="!svgLoaded" class="loadingSvg">
      <LoadingAnimation />
    </div>
    <svg v-show="svgLoaded" id="svgC" viewBox="0 0 1024 768" style="width: 100%;">
      <g id="gMap">
        <image :width="SvgMap.width" :height="SvgMap.height" :href="preview" id="imgbackground"></image>
      </g>
      <g id="MapPreviwX" v-if="!reload">
        <g :key="'poly-' + index" v-for="(shapes, index) in MapSvg.shapes">
          <g :id="'section-' + shapes.id_section" 
              @click="SelectSection(shapes, shapes.id_section)"
              @mouseenter="mouseoversection($event, shapes, shapes.id_section, true)" 
              @mouseleave="mouseoversection($event, shapes, shapes.id_section, false)"
              :class="classPoly(shapes)" 
              :style="{
                opacity: $store.state.sites.indexEveSelSection == shapes.id_section ? 0.9 : 0.6,
              }"
              v-if="(!activeNivel && shapes.showseat === 1) || (shapes.showseat === 0)"
              @mouseout="tooltipStatus(false)">
            <polygon :points="$root.PaintFigures(shapes.shapes)" :key="'polygon-' + index" :fill="shapes.quedan > 0 ? shapes.color : '#A4A4A4'"></polygon>
            <circle
              v-for="(c, index2) in shapes.circles"
              :cx="c.cx"
              :cy="c.cy"
              :r="c.r"
              :fill="shapes.quedan > 0 ? shapes.color : '#A4A4A4'"
              :key="'circle-' + index + '_' + index2"
            />
          </g>

          <g id="gMapC2" v-show="shapes.showseat === 1" v-if="shapes.seatm && activeNivel">
            <g :id="'Group_' + index0" v-for="(seatg1, index0) in shapes.seatm.seatg" :key="'Group_' + index0"
              :transform="
                'translate(' +
                seatg1.x_s +
                ',' +
                seatg1.y_s +
                ') scale(' +
                seatg1.k_s +
                ') rotate(' +
                seatg1.rotate +
                ')'
              " class="group">
              <g v-for="(row, index1) in seatg1.seat" :key="'Row-' + index0 + '-' + index1" class="Row"
                @mouseout="tooltipStatus(false)">
                <g v-for="(col, index2) in row" :key="'Col-' + index0 + '-' + index1 + '_' + index2">
                  <circle :id="'Gp_' + index + '_' + index0 + '_' + index1 + '_' + index2" 
                    @mouseenter="mouseover($event, index, index0, index1, index2)" 
                    :cx="col.x" :cy="col.y2" 
                    :r="col.radio" 
                    :style="{
                      fill: stateSeat(col),
                    }" 
                    :class="classSeatingPoint(col)"
                    @click="reserve(index, index0, index1, index2)">
                  </circle>
                </g>
              </g>
            </g>
          </g>
          <g v-if="(shapes.txtlabel.active && display) && (shapes.showseat !== 1 || !activeNivel)"
            :transform="`translate(${shapes.txtlabel.x}, ${shapes.txtlabel.y}) rotate(${shapes.txtlabel.r})`">
            <text :key="'text-' + index" :style="`font-size: ${shapes.txtlabel.fontSize}px; fill:${
                shapes.txtlabel.color ? shapes.txtlabel.color : 'black'
              }`">
              {{ shapes.section }}
            </text>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";
import LoadingAnimation from "../Tools/LoadingAnimation.vue";
var svg, g, p, MyZoom, TxtLabel, resetShape;
var Msvg, g, g11, gMap1, gMap2, gCircle, zoomed, rect, rectxt, zoomFunc, infox;

export default {
  name: "MapMixed",
  props: ["MapCharged"],
  components: {
    LoadingAnimation
  },
  data() {
    return {
      activeNivel: false,
      Config: null,
      SvgMap: null,
      UrlImg: "http://localhost/axess/place/",
      preview: null,
      MenuLayer: null,
      MenuXConfig: null,
      MapSvg: [],
      radius: 5,
      display: false,
      vEntorno: null,
      mouseDownX: false,
      Inputnumeric: null,
      watchScale: {},
      showZoomBtns: false,
      svgLoaded: false,
      reload: false
    };
  },
  created() {
    this.MenuLayer = this.$DesignLayout_CVListView;
    this.Config = this.$DesignLayout_EConfig;
    this.MenuXConfig = this.$DesignLayout_CMenuXConfig;
    this.SvgMap = this.$DesignLayout_ESvgMap;
    this.vEntorno = this.$store.state.sites;
    this.vEntorno.tickets = this.$store.state.shopping.regularSellTickets;
    this.Inputnumeric = this.$Inputnumeric;
  },
  beforeMount(){
    this.activeNivel = false;
    this.vEntorno = this.$store.state.sites;
    this.vEntorno.tickets = this.$store.state.shopping.regularSellTickets;
    this.Inputnumeric = this.$Inputnumeric;
  },
  mounted() {
    this.renderMap();
  },
  methods: {
    ...mapActions({
      resetSells: 'shopping/resetRegularSell',
      upgradeTicketSelection: 'shopping/updateRegularPurchase',
      changeSectionSelec: 'sites/changeSelectedSection'
    }),
    goBackHome() {      
      this.$router.push({
        name: 'eventPreview',
        query: { 
          e: this.vEntorno.events[0].name[this.$root.getTextlang()], 
          v: this.vEntorno.events[0].id_event
        }
      });
    },
    classSeatingPoint(col){
      if(!col.active) return 'pointer';

      if(col.id_sell){
        return 'pointer'
      }else{
        return 'pointman3'
      }
    },
    classPoly(shapes){
      let point
      // if(this.display && !this.activeNivel){
        if(shapes.showseat === 0){
          point = 'pointman'
        }else{
          point = ''
        }
      // }
      return point
    },
    opacityPoly(index, shapes){
      let opa
      
      if(this.$store.state.sites.indexEveSelSection == index){
        opa = 1
      }else{
        opa = 0.6
      }
      return opa
    },
    stateSeat(col){
      let color = 'black'
      if(!col.active){
        return 'transparent'
      }
      if(col.id_sell){
        return 'gray'
      }
      if(col.reserve == 'false'){
        color = 'black'
      }else{
        color = 'green'
      }
      //fill: col.reserve == 'false' ? 'black' : (col.active ? 'green' : 'transparent' ),
      return color
    },
    SelectSection(shapes, index) {
      if (shapes.showseat === 1 || !shapes.quedan) return false;
      this.changeSectionSelec( { index, section: shapes } );
      setTimeout(() => {
        let vx = this.vEntorno.tickets.findIndex( (busca) => busca.id_section === this.$store.state.sites.temp_eventSelect.id_section );
        if (vx > -1) {
          let tickets = this.vEntorno.tickets[vx];
          this.$set(this.$Inputnumeric, "cantidad", tickets.cantidad);
          this.$set( this.$Inputnumeric, "suma", tickets.cantidad * shapes.price );
        } else {
          this.$set(this.$Inputnumeric, "cantidad", 0);
          this.$set(this.$Inputnumeric, "suma", 0);
        }
        this.$set(this.$Inputnumeric, "precio", shapes.price);
      }, 10);
      this.tooltipStatus(false);
    },
    begin(info) {
      let vmx = this;
      let MapSvg01 = document.getElementById("gMapC");
      let MapSvg02 = document.getElementById("gMapC2");

      Msvg = d3.select("#svgC");
      gMap1 = d3.select(MapSvg01);
      gMap2 = d3.select(MapSvg02);
     
      zoomed = function (event) {
        vmx.LvlZoom = event.transform.k;
        let gMap = d3.select("#gMap");
        let gMapC = d3.select("#gMapC");
        let gMapC2 = d3.select("#MapPreviwX");
        vmx.watchScale = event.transform;
        
        vmx.verifyShowFigures(event.transform.k, info)

        if (vmx.Config.DeleteSeatActive) {
          gMap1.attr("transform", event.transform);
        }
        gMap.attr("transform", event.transform);
        gMapC.attr("transform", event.transform);
        gMapC2.attr("transform", event.transform);
      };

      zoomFunc = d3
        .zoom()
        .on("zoom", zoomed)
        .on("start", function () {
          vmx.mouseDownX = true;
        })
        .on("end", function () {
          vmx.mouseDownX = false;
        })
        .scaleExtent([info.client_zoom.kmin, info.client_zoom.kmax]);
      Msvg.call(zoomFunc);

      Msvg.call(
        zoomFunc.transform,
        d3.zoomIdentity
          .translate(info.client_zoom.x, info.client_zoom.y)
          .scale(info.client_zoom.kmin)
      );
      const svgElem = document.getElementById("svgC")

      let self = this
    
      svgElem.addEventListener('load', () => {
        self.svgLoaded = true;
      }, true)
    },
    renderMap() {
      try {
        this.MapSvg = null
        let self = this;
        this.reload = true
        if (this.MapCharged) {
          let Map = this.MapCharged;

          if (Map) {
            if (Map.disable_shapes) {
              this.display = false; 
            } else {
              this.display = true;
            }
            for (var x = 0; x < Map.shapes.length; x++) {
              Map.shapes[x].shapes = (typeof Map.shapes[x].shapes === "object") ? Map.shapes[x].shapes : JSON.parse(Map.shapes[x].shapes);
              Map.shapes[x].circles = (typeof Map.shapes[x].circles === "object") ? Map.shapes[x].circles : JSON.parse(Map.shapes[x].circles);
              Map.shapes[x].txtlabel = (typeof Map.shapes[x].txtlabel === "object") ? Map.shapes[x].txtlabel : JSON.parse(Map.shapes[x].txtlabel);
            }
            
            if (Map.client_zoom) {
              Map.client_zoom = (typeof Map.client_zoom === "object") ? Map.client_zoom : JSON.parse(Map.client_zoom);
              this.preview = this.$root.UrlImg + Map.map;
              this.begin(Map);
            }
          }
          this.MapSvg = Map;
          this.ChargeFirts()

          $(".mapSvg").removeClass("HiddeMe")
          self.reload = false

          self.$emit("loadErrorCharge", false)
        } else {
          this.$emit("loadErrorCharge", true)
        }
      } catch (e) {
        this.$emit("loadErrorCharge", true)
      }
    },
    ChargeFirts(){
      if(this.MapSvg.shapes.length == 1 && this.display == true){
        this.SelectSection(this.MapSvg.shapes[0], this.MapSvg.shapes[0].id_section)
      }
    },
    mouseover: function (event, shape, grupo, row, column) {
      let MyData = this.MapSvg.shapes[shape].seatm.seatg[grupo].seat[row][column];

      if (MyData.id_sell) {
        return false;
      }

      if (!MyData.active) return false;
      let style = "padding:2px 8px 2px 8px;";
      let style1 = "padding: 9px 8px 2px 8px;";

      let cardContent =
        '<div style="background: var(--color-primary); height: 15px;"></div>' +
        '<table style="margin-top:5px;">' +
        '<tr><td colspan="3" style="font-size:16px;"><strong>&nbsp;' +
        this.MapSvg.shapes[shape].area +
        "</strong></td></tr>" +
        "<tr>" +
        '<td style="' +
        style1 +
        ' font-size:11px; color:var(--color-primary);"><strong>SECTION</strong></td>' +
        '<td style="' +
        style1 +
        ' font-size:11px; color:var(--color-primary); border-left:1px solid #CCC;"><strong>ROW</strong></td>' +
        '<td style="' +
        style1 +
        ' font-size:11px; color:var(--color-primary); border-left:1px solid #CCC;"><strong>COLUMN</strong></td>' +
        "</tr>" +
        "<tr>" +
        '<td style="' +
        style +
        '">' +
        this.MapSvg.shapes[shape].section +
        "</td>" +
        '<td style="' +
        style +
        ' border-left:1px solid #CCC;">' +
        (row + 1) +
        "</td>" +
        '<td style="' +
        style +
        ' border-left:1px solid #CCC;">' +
        (column + 1) +
        "</td>" +
        "</tr>" +
        "</table>" +
        '<div style="background: #e8e8e8; height: 6px; margin-top:5px;"></div>' +
        '<div style="' +
        style1 +
        '">' +
        "<table><tr>" +
        '<td style="padding: 0px 10px 10px 10px;">' +
        '<div style="width:20px; height: 20px; border-radius:10px; background:var(--color-primary);"></div>' +
        "</td>" +
        "<td>" +
        (MyData.title ? MyData.title : "Unassigned") +
        "</td>" +
        "<td align='right' style='width:100px;'>" +
        "$" +
        this.MapSvg.shapes[shape].price +
        "</td>" +
        "</tr></table>" +
        "</div>";

      this.tooltipStatus(true, cardContent, event); 
    },
    mouseoversection(event, shape, index, flav) {
      if(shape.showseat === 1) {
         $("#section-"+index).css({
          opacity: 0.6
        }); 
        return false;
      }
      if(this.$store.state.sites.indexEveSelSection != index && shape.quedan > 0) {
        if (flav) {
          $("#section-"+index).css({
            opacity: 0.9
          });
        } else {
          $("#section-"+index).css({
            opacity: 0.6
          });
        }
      }     
      let MyData = shape;        
      if (!flav) return false;

      let style = "padding:2px 8px;";
      let style1 = "padding: 9px 8px 2px 8px;";
      let styleAvialable = "width: 10px; height: 10px; background-color: #3BAE17; margin-right: 4px; border-radius: 5px";
      let styleNOAvialable = "width: 10px; height: 10px; background-color: #C2C2C2; margin-right: 4px; border-radius: 5px";

      let cardContent =
        '<div style="background: var(--color-primary); height: 15px;"></div>' +
        '<table style="margin-top:5px;"><tr>' +
        '<td style="' +
        style1 +
        ' font-size:11px; color:var(--color-primary);"><strong>AREA</strong></td>' +
        '<td style="' +
        style1 +
        ' font-size:11px; color:var(--color-primary);"><strong>SECTION</strong></td>' +
        "</tr>" +
        "<tr>" +
        '<td style="' +
        style +
        '">' +
        MyData.area +
        "</td>" +
        '<td style="' +
        style +
        '">' +
        MyData.section +
        "</td>" +
        "</tr>" +
        "</table>" +
        '<div style="background: #e8e8e8; height: 6px; margin-top:5px;"></div>' +
        '<div style="' +
        style1 +
        '">' +
        '<table><tr style="display:flex; flex-direction: row">' +
        "<td align='left' style='display: flex; flex-direction: row; align-items: center;'>"+
          `<div style="${MyData.quedan > 0 ? styleAvialable : styleNOAvialable}"></div> <span style="font-size: 12px !important;" class="font-weight-bold text-uppercase"> ${MyData.quedan > 0 ? this.$t("actions.limitedAvai") : this.$t("actions.limitedAvai2")} </span>` +
        "</td>" +
        "<td align='center' style='width:100px;'>" +
        "$" +
        this.$root.formatPrice(MyData.price) +
        "</td>" +
        "</tr></table>" +
        "</div>";

      this.tooltipStatus(flav, cardContent, event); 
    },
    tooltipStatus(showTooltip, tooltipBody, event) {
      if(showTooltip) {
        $(".MyToolTip").html(tooltipBody);

        let top     = event.y - 200
        let left    = event.x + 10
        let maxTop  =  $('.mapSvg').outerHeight() - $('.MyToolTip').outerHeight() - 1
        let maxLeft = $('.mapSvg').outerWidth() - $('.MyToolTip').outerWidth() - 10
        
        let minTop = 0
        top = top < minTop ? minTop : top
        top = top > maxTop ? maxTop : top
        left = left < minTop ? minTop : left
        left = left > maxLeft ?  (event.x - $('.MyToolTip').outerWidth() - 10) : left

        
        $(".MyToolTip").css({
          display: "block",
          left: left + 'px',
          top: top + 'px',
        });
        return false;
      }
      
      $(".MyToolTip").css({ display: "none" });
    },
    reserve(shape, grupo, row, column, reservex) {
      this.changeSectionSelec({ index: null, section: null })
      let MyData = this.MapSvg.shapes[shape].seatm.seatg[grupo].seat[row][column];
      if(!MyData.active) return;

      let { id_section, id_shape, price, area, section, showseat } = this.MapSvg.shapes[shape];
      let { id_mgroups , id_boleto, id_groups, id_seating, title } = this.MapSvg.shapes[shape].seatm.seatg[grupo].seat[row][column];

      if (MyData.id_sell) {
        return false;
      }

      if (reservex) {
        this.MapSvg.shapes[shape].seatm.seatg[grupo].seat[row][
          column
        ].id_sell = 1;
      }

      if (MyData.reserve == "true") {        
        this.MapSvg.shapes[shape].seatm.seatg[grupo].seat[row][column].reserve = "false";
        this.vEntorno.temp_total = parseFloat(this.vEntorno.temp_total) - parseFloat(this.MapSvg.shapes[shape].price);

        var data = this.vEntorno.tickets.filter(function (e) {
          return e.id_seating != id_seating;
        });

        this.vEntorno.tickets = data;
      } else {
        this.MapSvg.shapes[shape].seatm.seatg[grupo].seat[row][column].reserve = "true";
        this.vEntorno.temp_total = parseFloat(this.vEntorno.temp_total) + parseFloat(this.MapSvg.shapes[shape].price);
        this.vEntorno.tickets.push({
          id_section,
          id_shape,
          id_mgroups,
          id_boleto,
          id_groups,
          id_seating,
          shape,
          grupo,
          row,
          column,
          rowx: row + 1,
          columnx: column + 1,
          title,
          price,
          area,
          section,
          showseat
        });
      }
      this.vEntorno.tickets.sort((a, b) => {
        if (!a.id_shape) {
          return 1;
        }
        else {
          return -1;
        }
      });
      
      this.vEntorno.tickets = this.vEntorno.tickets.reverse();
      this.upgradeTicketSelection(this.vEntorno.tickets);
    },
    ZoomWithButtom(addZoom) {
      let GMap = d3.select("#gMap")
      let mapPreviwX = d3.select("#MapPreviwX")
      let tempZoom = (this.watchScale.k + addZoom)

      if(tempZoom < this.MapSvg.client_zoom.kmin) {
        this.watchScale.k = this.MapSvg.client_zoom.kmin
      }
      else if (tempZoom > this.MapSvg.client_zoom.kmax) {
        this.watchScale.k = this.MapSvg.client_zoom.kmax
      }
      else {        
        this.watchScale.k = tempZoom
        this.watchScale.x = parseFloat(this.watchScale.x - 500 * addZoom)
        this.watchScale.y = parseFloat(this.watchScale.y - 200 * addZoom)
        GMap.attr("transform", "translate("
          + this.watchScale.x + "," + this.watchScale.y
          + ") scale(" + parseFloat(this.watchScale.k) + ")")
        mapPreviwX.attr("transform", "translate("
          + this.watchScale.x + "," + this.watchScale.y
          + ") scale(" + parseFloat(this.watchScale.k) + ")")
      }
      this.verifyShowFigures(tempZoom, this.MapSvg)
    },
    verifyShowFigures: function (tempZoom, info) {
      let zz
      if(info.client_zoom.kmid) {
        zz = info.client_zoom.kmid
      } else {
        zz = info ? info.client_zoom.kmax + info.client_zoom.kmin : this.MapSvg.client_zoom.kmax + this.MapSvg.client_zoom.kmin;
        zz = zz / 5;
      }      
      this.activeNivel = !(this.display == true && tempZoom <= zz);
    },
  },
};
</script>

<style scoped>
.loadingSvg {
  position: absolute;
  top: 40vh;
  left: calc(50%);
}
#svgC {
  height: 100vh !important;
}
.ZoomBtn{
  position: absolute;
  top: 5px;
  right: 5px;
}
.pointman2 {
  cursor: pointer;
}
.pointman3 {
  cursor: pointer;
}
.pointer{
  cursor: initial !important;
}
.pointman {
  cursor: pointer;
}
.MyToolTip {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  background: white;
  border-radius: 6px;
  border: 1px solid #ccc;
  display: none;
}
.strokeclas {
  stroke-width: 3px;
}
@media only screen and (max-device-width: 800px) {
  #svgC {
    height: 100% !important;
  }
  .loadingSvg {
    top: 20vh;
  }
}
@media only screen and (max-device-width: 500px) {
  #svgC {
    height: 100% !important;
  }
  .loadingSvg {
    top: 30vh;
  }
}
</style>
