<template>
 <div class="CardInput">
   <div class="Lebox">
     <div>
       <v-btn
         class="Lebtn LeRigth"
         outlined
         color="indigo"
         @click="lessinput()"
       >
         <span class="material-icons"> remove </span>
       </v-btn>
     </div>
     <div>
       <input
         v-model="Inputnumeric.cantidad"
         @keypress="isNumber($event)"
         @keyup="changeinput()"
         class="MyInputNumber"
         maxlength="3"
       />
     </div>
     <div>
       <v-btn class="Lebtn LeLeft" outlined color="indigo" @click="addinput()">
         <span class="material-icons"> add </span>
       </v-btn>
     </div>
   </div>
 </div>
</template>


<script>
export default {
 name: "InputNumericVue",
 props: ["cantidad", "precio", "max","index"],
 data() {
   return {
     incress: 1,
     Inputnumeric: null,
     cantidadX : 0,
     tikcets2: [],
     place: "",
     ini: true,
     rol: 0,
   };
 },
 created() {
   this.$set(this.$Inputnumeric, "cantidad", 0);
   this.$set(this.$Inputnumeric, "precio", 0);
   this.$set(this.$Inputnumeric, "suma", 0);
   this.Inputnumeric = this.$Inputnumeric;
 },
 mounted() {
   this.$set(this.$Inputnumeric, "cantidad", 0);
   this.$set(this.$Inputnumeric, "precio", 0);
   this.$set(this.$Inputnumeric, "suma", 0);
   this.Inputnumeric = this.$Inputnumeric;
 },
 methods: {
   formatPrice(value) {
     if (!value) {
       value = 0;
     }
     if (typeof value === "string") {
       value = parseFloat(value);
     }
     value = value.toFixed(2);
     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   },
   isNumber: function (evt) {
     evt = evt ? evt : window.event;
     var charCode = evt.which ? evt.which : evt.keyCode;
     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
       evt.preventDefault();
     } else {
       return true;
     }
   },
   changeinput() {
     if(this.index != null){
       if (parseInt(this.cantidadX) > this.max) {
         
         this.cantidadX = this.max
         this.Inputnumeric.tikcets[this.index].cantidad = this.max;

         if (this.Inputnumeric.tikcets[this.index].price) {
           this.Inputnumeric.tikcets[this.index].suma = parseFloat(this.Inputnumeric.tikcets[this.index].price) * this.max;
         }
       } else {
         this.Inputnumeric.tikcets[this.index].cantidad = parseInt(this.cantidadX)
         this.Inputnumeric.tikcets[this.index].suma = parseFloat(this.Inputnumeric.tikcets[this.index].price) * this.Inputnumeric.tikcets[this.index].cantidad;
       }
       this.update2();
     }else{
       if (parseInt(this.Inputnumeric.cantidad) > this.max) {
         this.Inputnumeric.cantidad = this.max;
         if (this.Inputnumeric.precio) {
           this.Inputnumeric.suma =
             parseFloat(this.Inputnumeric.precio) * this.max;
         }
       } else {
         this.Inputnumeric.suma =
           parseFloat(this.Inputnumeric.precio) * this.Inputnumeric.cantidad;
       }
       this.updateData();
     }
   },
   lessinput() {
     if(this.index != null){
       let cantidad = parseInt(this.Inputnumeric.tikcets[this.index].cantidad) - this.incress;
       if (cantidad <= 0) {
         cantidad =0;
       }
       this.cantidadX = cantidad;
       this.Inputnumeric.tikcets[this.index].cantidad = cantidad

       if (this.Inputnumeric.tikcets[this.index].price) {
         this.Inputnumeric.tikcets[this.index].suma = parseFloat(this.Inputnumeric.tikcets[this.index].price) * cantidad;
       }
       this.update2()

     }else{

       let cantidad = parseInt(this.Inputnumeric.cantidad) - this.incress;
       if (cantidad <= 0) {
         cantidad = 0;
       }
       this.cantidadX = cantidad;
       this.Inputnumeric.cantidad = cantidad;
       if (this.Inputnumeric.precio) {
         this.Inputnumeric.suma =
           parseFloat(this.Inputnumeric.precio) * cantidad;
       }
       this.updateData();
     }
   },
   addinput() {
     if(this.index != null){
       let cantidad = parseInt(this.Inputnumeric.tikcets[this.index].cantidad) + this.incress;
       if (cantidad > this.max) {
         cantidad = this.max;
       }
       this.cantidadX = cantidad;
       this.Inputnumeric.tikcets[this.index].cantidad = cantidad

       if (this.Inputnumeric.tikcets[this.index].price) {
         this.Inputnumeric.tikcets[this.index].suma = parseFloat(this.Inputnumeric.tikcets[this.index].price) * cantidad;
       }
       this.update2()

     }else{
       let cantidad = parseInt(this.Inputnumeric.cantidad) + this.incress;
       if (cantidad > this.max) {
         cantidad = this.max;
       }
       this.Inputnumeric.cantidad = cantidad;
       this.cantidadX = cantidad;

       if (this.Inputnumeric.precio) {
         this.Inputnumeric.suma =
           parseFloat(this.Inputnumeric.precio) * cantidad;
       }
       this.updateData();
     }      
   },
   updateData() {
     this.$set(this.$Inputnumeric, "cantidad", this.Inputnumeric.cantidad);
     this.$set(this.$Inputnumeric, "suma", this.Inputnumeric.suma);
   },
   update2(){
     this.$set(this.$Inputnumeric, "tikcets", this.Inputnumeric.tikcets);
     this.$emit('getSuma', { index:this.index, tikcets: this.Inputnumeric.tikcets })
     let sum = 0
     let cantidad = 0
     this.Inputnumeric.tikcets.map(function(num) {
       if(num.suma){
         sum = sum + parseFloat(num.suma)
       }
       cantidad = cantidad + parseInt(num.cantidad)
     });
     this.$set(this.$Inputnumeric, "suma", sum);
     this.$set(this.$Inputnumeric, "cantidad", cantidad);
   }
 },
};
</script>

<style scoped>
.CardInput {
 padding: 0px 10px 10px 10px;
 width: fit-content !important;
}

.Lebox {
 display: flex;
 flex-flow: row wrap;
 width: 100%;
 flex-wrap: nowrap;
}

.Lebtn {
 width: 30px !important;
 min-width: 30px !important;
 margin: 0 !important;
 height: 35px !important;
 background: #ffffff;
}

.Lebtn:hover {
 background: var(--color-primary) !important;
}

.Lebtn:hover span {
 color: white !important;
}

.Lebtn span {
 font-size: 22px;
 margin-left: -2px;
 font-weight: bold;
}

.LeLeft {
 border-radius: 0px 5px 5px 0px;
 border-left: none;
 height: 100% !important;
}

.MyInputNumber {
 font-size: 26px;
 background: white;
 padding: 3px;
 border: 1px solid black;
 width: 100%;
 text-align: center;
 height: 100% !important;
 margin-top: 0px;
}

.LeRigth {
 border-radius: 5px 0px 0px 5px !important;
 border-right: none !important;
 height: 100% !important;
}
</style>