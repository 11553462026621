<template>
  <div v-if="vEntorno.events[vEntorno.indexEveSel]">
    <div
      class="HeaderEvent"
      :style="{
        'background-image':
          'linear-gradient(to right, rgba(0, 0, 0, 0.5) 60%, transparent 90%), url(' +
          $root.pathevens +
          vEntorno.events[vEntorno.indexEveSel].img +
          ')',
      }"
      v-if="vEntorno.events[vEntorno.indexEveSel].img"
    ></div>
    <div class="InfoEvent">
      <div>
        <div class="ContHearderInfo">
          <div class="DivImg">
            <img
              :src="$root.pathevens + vEntorno.events[vEntorno.indexEveSel].img"
              style="max-height: 100%"
            />
          </div>
          <div class="ContHearderInfo2">
            <div>
              <strong>{{ vEntorno.events[vEntorno.indexEveSel].name[$root.getTextlang()] }}</strong>
            </div>
            <div class="TitlesEvents">
              <div style="display: flex">
                <div>
                  {{ vEntorno.eventselect.time_event }} HRS - {{ getFormatDate(vEntorno.eventselect.date_event) }}
                </div>
              </div>
              <div style="display: flex">
                <div>
                  {{ vEntorno.events[vEntorno.indexEveSel].place }},
                  {{ vEntorno.events[vEntorno.indexEveSel].city }},
                  {{ vEntorno.events[vEntorno.indexEveSel].state }}
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-primary-outline"
                v-if="false">
                <v-icon color="white" style="font-size: 20px; margin-top: -2px">
                  mdi-information-variant
                </v-icon>
                información
              </button>
              <button
                type="button"
                class="btn btn-primary-outline"
                style="margin-left: 15px"
                v-if="false"
              >
                <v-icon color="white" style="font-size: 20px; margin-top: -2px">
                  mdi-account
                </v-icon>
                Boletos para personas con discapacidad
              </button>
            </div>
          </div>
        </div>
        <div align="left" class="Notification">
          {{ $t("actions.limitEdad") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "HeaderEvent",
  data() {
    return {
      vEntorno: null,
    };
  },
  created() {
    this.vEntorno = this.$store.state.sites;
  },
  methods: {
    ...mapActions({
      clearTickets: 'shopping/resetRegularSell'
    }),
    BackHome(){
      this.clearTickets();
      this.$router.push({
        name: 'eventPreview',
        query: { 
          e: this.vEntorno.events[0].name[this.$root.getTextlang()], 
          v: this.vEntorno.events[0].id_event
        }
      });
    },
    getFormatDate(date) {
      let dateX = new Date(date)
      dateX.setMinutes(dateX.getMinutes() + dateX.getTimezoneOffset())
      return dateX.toLocaleDateString(this.$root.getTextlang(), { weekday: 'long', year:"numeric", month:"long", day:"numeric"}) 
    }
  },
};
</script>

<style scoped>
.pointman {
  cursor: pointer;
}
.InfoTickets {
  position: absolute;
  top: 139px;
  width: 100vw;
  height: 60px;
  background: white;
  z-index: 1;
}
.Notification {
  width: 100vw;
  margin-left: -17px;
  margin-top: 7px;
  background: #00000099;
  font-size: 11px;
  padding: 8px 2px 8px 12px;
  position: absolute;
}
.btn-primary-outline {
  background-color: transparent;
  border-color: #ccc;
  font-weight: bold;
  margin: 1px;
  font-size: 13px;
}

.btn-primary-outline:hover {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  margin: 0px;
}
.mySeparador {
  padding: 0px 5px 0px 5px;
  margin-top: -2px;
  font-size: 16px;
}
.ContHearderInfo2 {
  padding: 0px 0px 0px 20px;
  text-align: left;
}
.ContHearderInfo {
  display: flex;
  padding: 10px;
}
.DivImg {
  width: 150px;
  height: 84px;
  margin: 0px 16px 0px 0px;
}
.HeaderEvent {
  height: 106px;
  background-size: cover;
  filter: blur(15px);
  background-position: 50% 60%;
  color: white;
}
.InfoEvent {
  position: absolute;
  color: white;
  top: 0px;
  left: 18px;
}

@media (max-width: 800px) {
  .Notification {
    margin-top: -20px;
    font-size: 9px;
  }
  .HeaderEvent {
    height: 132px;
  }
  .InfoEvent {
    top: 66px;
    left: 16px;
  }
  .ContHearderInfo {
    padding: 10px 10px 10px 0px;
  }
}

@media (max-width: 700px) {
  .ContaneirTickets{
    height: calc(100vh - 410px) !important;
  }
  .TitlesEvents{
    font-size: 12px;
  }
}
</style>
