<template>
 <div style="padding: 10px 19px 15px">
   <v-card style="padding-top: 10px">
     <v-row class="MyPadding">
       <v-col cols="3" align="left">
         <div class="Pleft">
           <div class="Mycolor">{{ $t("actions.cardinfo.section") }}</div>
           <div class="MyFont1">{{ item.section }}</div>
         </div>
       </v-col>
       <v-col cols="3" align="left">
         <div style="border-left: 2px solid #dddcdc !important" class="Pleft">
           <div class="Mycolor">{{ $t("actions.cardinfo.row") }}</div>
           <div class="MyFont1">{{ item.rowx }}</div>
         </div>
       </v-col>
       <v-col cols="3" align="left">
         <div style="border-left: 2px solid #dddcdc !important" class="Pleft">
           <div class="Mycolor">{{ $t("actions.cardinfo.colum") }}</div>
           <div class="MyFont1">{{ item.columnx }}</div>
         </div>
       </v-col>
       <v-col cols="3" align="left">
         <div style="border-left: 2px solid #dddcdc !important" class="Pleft">
           <div class="Mycolor">{{ $t("actions.cardinfo.seat") }}</div>
           <div class="MyFont1">{{ item.title }}</div>
         </div>
       </v-col>
     </v-row>
     <div class="MyPadding" align="left">
       <div class="MyLimitBorder"></div>
       <div style="display: flex; align-items: center">
         <div class="half Mymoney">${{ item.price }}</div>
         <div class="half" align="right" style="padding: 10px">
           <v-tooltip top>
             <template v-slot:activator="{ on, attrs }">
               <v-btn
                 dark
                 color="var(--color-primary)"
                 elevation="2"
                 x-small
                 fab
                 v-bind="attrs"
                 v-on="on"
                 @click="removeTicket(item)"
               >
                 <v-icon>mdi-trash-can</v-icon>
               </v-btn>
             </template>
             <span>Quitar de la lista</span>
           </v-tooltip>
         </div>
       </div>
     </div>
   </v-card>
 </div>
</template>

<script>
import $ from "jquery";

export default {
 name: "Boletos",
 props: ["item"],
 date() {
   return {
     vEntorno: null,
   };
 },
 created() {
   this.vEntorno = this.$vEntorno;
 },
 methods: {
   removeTicket(item) {

     this.$swal({
       title: 'Deseas eliminar el boleto?',
       text: 'Asiento: '+item.title,
       showDenyButton: true,
       showCancelButton: false,
       confirmButtonText: 'Si',
     }).then((result) => {
       if (result.isConfirmed) {
         $("#Gp_" + item.shape + "_" + item.row + "_" + item.column + "").css({
           stroke: "transparent",
         });

         var data = this.$vEntorno.tickets.filter(function (e) {
           return e.id_seating != item.id_seating;
         });

         this.vEntorno.tickets = data;
         this.$set(this.$vEntorno, "tickets", this.vEntorno.tickets);

         this.$parent.$parent.$refs.MapClient.reserve(
           item.shape,
           item.grupo,
           item.row,
           item.column
         );
       } else if (result.isDenied) {
         
       }
     })
   },
 },
};
</script>

<style scoped>
.Mymoney {
 padding: 10px;
 font-weight: bold;
 font-size: 16px;
}
.Mycolor {
 color: var(--color-primary);
 font-size: 14px;
}
.MyFont1 {
 font-size: 14px;
}
.Pleft {
 padding-left: 10px;
}
.half {
 width: 50%;
}
.MyLimitBorder {
 border-bottom: 2px solid #dddcdc;
 padding: 0px 10px 10px 10px !important;
 text-align: left;
 display: flex;
}
.MyPadding {
 padding: 0px 10px 0px 10px !important;
}
.hr-vertical {
 border: none;
 border-left: 1px solid hsla(200, 10%, 50%, 100);
 height: 100px;
 width: 1px;
}
@media (max-width: 800px) {
 .Mycolor {
   font-size: 11px;
 }
 .MyFont1 {
   font-size: 12px;
 }
}
</style>