var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.vEntorno.events[_vm.vEntorno.indexEveSel])?_c('div',[(_vm.vEntorno.events[_vm.vEntorno.indexEveSel].img)?_c('div',{staticClass:"HeaderEvent",style:({
      'background-image':
        'linear-gradient(to right, rgba(0, 0, 0, 0.5) 60%, transparent 90%), url(' +
        _vm.$root.pathevens +
        _vm.vEntorno.events[_vm.vEntorno.indexEveSel].img +
        ')',
    })}):_vm._e(),_c('div',{staticClass:"InfoEvent"},[_c('div',[_c('div',{staticClass:"ContHearderInfo"},[_c('div',{staticClass:"DivImg"},[_c('img',{staticStyle:{"max-height":"100%"},attrs:{"src":_vm.$root.pathevens + _vm.vEntorno.events[_vm.vEntorno.indexEveSel].img}})]),_c('div',{staticClass:"ContHearderInfo2"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.vEntorno.events[_vm.vEntorno.indexEveSel].name[_vm.$root.getTextlang()]))])]),_c('div',{staticClass:"TitlesEvents"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',[_vm._v(" "+_vm._s(_vm.vEntorno.eventselect.time_event)+" HRS - "+_vm._s(_vm.getFormatDate(_vm.vEntorno.eventselect.date_event))+" ")])]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',[_vm._v(" "+_vm._s(_vm.vEntorno.events[_vm.vEntorno.indexEveSel].place)+", "+_vm._s(_vm.vEntorno.events[_vm.vEntorno.indexEveSel].city)+", "+_vm._s(_vm.vEntorno.events[_vm.vEntorno.indexEveSel].state)+" ")])])]),_c('div',[(false)?_c('button',{staticClass:"btn btn-primary-outline",attrs:{"type":"button"}},[_c('v-icon',{staticStyle:{"font-size":"20px","margin-top":"-2px"},attrs:{"color":"white"}},[_vm._v(" mdi-information-variant ")]),_vm._v(" información ")],1):_vm._e(),(false)?_c('button',{staticClass:"btn btn-primary-outline",staticStyle:{"margin-left":"15px"},attrs:{"type":"button"}},[_c('v-icon',{staticStyle:{"font-size":"20px","margin-top":"-2px"},attrs:{"color":"white"}},[_vm._v(" mdi-account ")]),_vm._v(" Boletos para personas con discapacidad ")],1):_vm._e()])])]),_c('div',{staticClass:"Notification",attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.$t("actions.limitEdad"))+" ")])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }