<template>
    <div v-if="!$root.loading" 
      :class="[
        {'nodata-style': onDateLoadError},
        {'light-style':!$store.state.corpInfo.darkTheme}
      ]"
    >
    <div class="ClientContainer" v-if="!onDateLoadError && MapCharged">
      <div class="full">
        <div class="full" v-if="comprando">          
         <HeaderEvent v-if="vEntorno.eventselect" style="background: #080c16" />  
         <div class="clientBox" v-if="true">
           <div class="mapSvg HiddeMe">
             <MapClientMixed ref="MapClient" 
              @loadErrorCharge="loadNotFoundPage" 
              :MapCharged="MapCharged"
            />
           </div>
           <div class="ticketSell">
             <MapSelectedTickets ref="MapSelectedTickets" 
                style="height: 100%" 
                @startReserve="startReserve" 
                @openDialogTicketSel="openParticipantsDialog()"
              />
           </div>
         </div>
       </div>
      </div>
    </div>

    <div v-if="!onDateLoadError && !MapCharged" class="mx-auto d-flex flex-column grey lighten-4" style="height: 100vh;">
      <v-row class="mb-0 contain">
        <v-col cols="12" class="pa-0">
          <v-skeleton-loader
            type="table-heading, list-item-two-line"
          ></v-skeleton-loader>            
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 pt-1 mb-6">
        <v-col cols="12" md="8" lg="8" class="pa-0 grey lighten-2" style="min-height: 250px;">
        </v-col>
        <v-col cols="12" md="4" lg="4"  class="pa-0 grey lighten-3">
          <v-skeleton-loader
            type="article, actions"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article, actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div v-if="onDateLoadError" style="height: 100%;">
      <NotFoundBody />
    </div>

    <ticketsPersonTypeDialog 
      v-model="ticketsDialog" 
      @cancelSelecParticipants="cancelParticipants"
      :showClose="firstDialogCharge"/>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import HeaderEvent from "../components/HeaderEvent.vue";
import MapClientMixed from "../components/Sell/MapClientMixed.vue";
import MapSelectedTickets from "../components/Sell/mapSelectedTickets.vue";
import NotFoundBody from '../components/404-NotFound/404-body.vue';
import ticketsPersonTypeDialog from '../components/Tools/SelectTicketQuantitiesDialog.vue'

export default {
  name: "Sell",
  components: {
    HeaderEvent,
    MapClientMixed,
    MapSelectedTickets,
    NotFoundBody,
    ticketsPersonTypeDialog
  },
  data() {
    return {
      comprando: true,
      tickets: [],
      vEntorno: null,
      onDateLoadError: false,
      MapCharged: null,
      ticketsDialog: false,
      firstDialogCharge: false
    };
  },
  created() {
    this.$root.token = this.$store.getters['auth/getUserS']?.token_cliente
    this.vEntorno = this.$store.state.sites;
    this.vEntorno.tickets = this.$store.state.shopping.regularSellTickets;
  },
  beforeMount() {
    this.vEntorno = this.$store.state.sites;
    this.loadMap()
  },
  mounted() {
    this.firstDialogCharge = false
    this.$root.resetShoppingTime()
    this.$root.value2 = 0;
    this.$root.value = 0;
    this.$root.showlogin = false;
    let captcha = document.getElementsByClassName('grecaptcha-badge')
    if (captcha && captcha.length > 0) {
      captcha[0].remove();
    }
  },
  methods: {
    ...mapActions({
      resetSells: 'shopping/resetRegularSell',
      upgradeTicketSelection: 'shopping/updateRegularPurchase',
      setTokenReservation: 'shopping/setTokenReservation',
      resetForMapValues: 'sites/resetMapEventValues'
    }),
    async startReserve(eventType, tickets) {
      this.$root.loading = true
      this.onDateLoadError = false;
      if(eventType === 5) {
        let shop = await this.prepareMapPurchase()
        if(shop && shop.length > 0) {
          let token = await this.ReserveTicket(shop);
          if(token) {
            this.upgradeTicketSelection(tickets)
            this.setTokenReservation(token.transac)
            this.$router.replace({ name: "sell" });
          } else {
            this.resetSells()
            this.resetForMapValues()
            await this.loadMap()
          }
        } else {
          this.$root.swalAlert("warning", this.$t('messages.ticketsNumberMustZero'), null);
        }
      } 
      this.$root.loading = false
    },
    prepareMapPurchase() {
      let vmx = this;
      this.vEntorno.tickets = this.$store.state.shopping.regularSellTickets;
      let shop = [];        
      if (vmx.vEntorno.tickets) {
        for (var x = 0; x < vmx.vEntorno.tickets.length; x++) {
          if(vmx.vEntorno.tickets[x].cantidad) {
            for(let canti = 0; canti < vmx.vEntorno.tickets[x].cantidad; canti ++) {
              shop.push([
                vmx.$root.id_corp,
                vmx.vEntorno.events[vmx.vEntorno.indexEveSel].id_place,
                vmx.vEntorno.tickets[x].id_shape,
                vmx.vEntorno.tickets[x].id_section,
                vmx.vEntorno.tickets[x].id_mgroups,
                vmx.vEntorno.tickets[x].id_groups,
                9999,
                vmx.vEntorno.tickets[x].id_seating,
                vmx.vEntorno.eventselect.id_event,
                vmx.vEntorno.eventselect.id_eventd,
                0,
              ]);
            }
          } else {
            shop.push([
              vmx.$root.id_corp,
              vmx.vEntorno.events[vmx.vEntorno.indexEveSel].id_place,
              vmx.vEntorno.tickets[x].id_shape,
              vmx.vEntorno.tickets[x].id_section,
              vmx.vEntorno.tickets[x].id_mgroups,
              vmx.vEntorno.tickets[x].id_groups,
              9999,
              vmx.vEntorno.tickets[x].id_seating,
              vmx.vEntorno.eventselect.id_event,
              vmx.vEntorno.eventselect.id_eventd,
              0,
            ]);
          }            
        }
        return shop
      } else {
        return false
      }
    },
    async ReserveTicket(shop) {
      let vmx = this
      this.$root.loading = true
      
      let response = await this.$root.post("/ServiceClient/ReserveTicket", {
        shop: shop,
        id_corp: vmx.$root.id_corp,   //id_client
        event_config: vmx.vEntorno.events[vmx.vEntorno.indexEveSel].event_config
      });
      let info = response.data;
      if (info.success == "FALSE") {
        if(info.Msg) {
          vmx.$root.swalAlert("warning", info.Msg, null);
        }
        else {                  
          switch ( info.statusError ) {
            case 1:
              for (var x = 0; x < info.rows.length; x++) {
                var result = tickets.find(
                  (busca) => busca.id_seating === info.rows[x].id_seating
                  );
                  this.$refs.MapClient.reserve(
                    result.shape,
                    result.grupo,
                    result.row,
                    result.column,
                    1
                );
              }
            break;
          }
        }    
        this.$root.loading = false   
        if(info.statusError !== null && info.statusError !== undefined) {
          this.$root.swalAlert(
            "warning",
            this.$t('messages.reservationProblem')[info.statusError],
            ""
          );
        }  
        return false
      } else {           
        this.$root.beginTime();
        return info.rows; 
      }
    },
    loadNotFoundPage(val) {
      this.onDateLoadError = val;
    },
    loadMap() {
      this.ticketsDialog = false
      let chargeShortInfo = !!!this.vEntorno.eventselect && this.vEntorno.events.length === 0
      this.$root.loading = true
      let self = this;
      self.MapCharged = null
      this.$root
      .post("/ServiceClient/GetEventMap", {
        id_event: this.$route.query.e,
        id_eventd: this.$route.query.ee,
        chargeShortInfo: chargeShortInfo,
        host: this.$root.hostname
      })
      .then((response) => {
        let info = response.data        
        if(info.success == 'TRUE') {
          self.onDateLoadError = false;
          this.ticketsDialog = this.$store.state.sites.statistics !== 0
          if(info.event && info.eventselect) {
            self.$store.dispatch('sites/chargeCurrentEvent', info.event)
            self.$store.dispatch('sites/selectEventDate', { eventDate: info.eventselect, index: null })
          }
          self.MapCharged = info.s;
        } else {
          this.$root.swalAlert('warning', '', this.$t('messages.notFoundMess.notFoundEvent'));
          this.onDateLoadError = true;
        }
      })
      .catch((e) => {
        this.ticketsDialog = false
        this.onDateLoadError = true;
      })
      .finally(() => {
        this.$root.loading = false;
      });
    },
    cancelParticipants() {
      this.ticketsDialog = false
      if(!this.firstDialogCharge) {
        this.$refs.MapClient.goBackHome()
        this.$store.dispatch('shopping/resetStadisticsValue');
      }
    },
    openParticipantsDialog() {
      this.firstDialogCharge = true
      this.ticketsDialog = true
    }
  },
};
</script>

<style scoped>
.grecaptcha-badge {
  display: none !important;
}
.contain {
  max-height: 130px;
}
.headertext {
  padding-top: 19px;
  font-weight: bold;
}
.nodata-style{
  height: 100%;
  background-color: black;
}.light-style {
  background-color: #E2E6EF !important;
}
.HeaderImg {
  max-width: 130px;
  opacity: 1 !important;
  margin-top: 8px
}
.v-progress-circular {
  margin: 1rem;
  height: 55px !important;
}
.f1 {
  background: white;
}
.subheader {
  background: var(--color-primary-light);
  background: linear-gradient(207deg,
      var(--color-primary-light) 0%,
      var(--color-primary) 45%,
      var(--color-primary-light1) 99%) !important;
}
.MyRelative {
  z-index: 1;
}
.full {
  width: 100%;
  height: 100%;
  background: white;
}
.ClientContainer {
  height: calc(100vh - 106px);
  width: 100vw;
  background-color: rgb(31, 38, 45);
}
.clientBox {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.clientBox .mapSvg {
  background: rgb(232, 236, 242);
  width: calc(100% - 500px);
  overflow: hidden;
}
.clientBox .ticketSell {
  background: white;
  border-left: 1px solid #dfe4e7;
  width: 500px;
  /* height: calc(100% - 224px); */
  height: 100%;
}

@media (max-width: 800px) {
  .clientBox {
    background: white;
    height: calc(100vh - 200px) !important;
    overflow: auto !important;
    overflow-x: hidden !important;
    display: block !important;
  }
  .ticketSell {
    position: absolute;
    width: 100% !important;
  }

  .mapSvg {
    height: 310px !important;
    width: 100% !important;
  }
  .v-progress-circular {
    height: 47px !important;
  }
  .headertext {
    font-size: 10px;
    margin-top: -7px;
  }
  .HeaderImg {
    max-width: 90px;
  }
  .HiddeMe {
    display: none !important;
  }
}
</style>